/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        owner
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getContactUsForm = /* GraphQL */ `
  query GetContactUsForm($id: ID!) {
    getContactUsForm(id: $id) {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const listContactUsForms = /* GraphQL */ `
  query ListContactUsForms(
    $filter: ModelContactUsFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactUsForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        firstName
        lastName
        email
        phone
        state
        zipcode
        message
        searchFirstName
        searchLastName
        createdAt
        contactID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDownloadFileForm = /* GraphQL */ `
  query GetDownloadFileForm($id: ID!) {
    getDownloadFileForm(id: $id) {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const listDownloadFileForms = /* GraphQL */ `
  query ListDownloadFileForms(
    $filter: ModelDownloadFileFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDownloadFileForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        lastName
        email
        productId
        productName
        searchFirstName
        searchLastName
        state
        zipcode
        createdAt
        contactID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTradeProgramForm = /* GraphQL */ `
  query GetTradeProgramForm($id: ID!) {
    getTradeProgramForm(id: $id) {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const listTradeProgramForms = /* GraphQL */ `
  query ListTradeProgramForms(
    $filter: ModelTradeProgramFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTradeProgramForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        searchFirstName
        lastName
        searchLastName
        email
        title
        companyName
        searchCompanyName
        streetAddress
        city
        state
        zipcode
        website
        phone
        fileName
        fileUrl
        createdAt
        contactID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubmissionForm = /* GraphQL */ `
  query GetSubmissionForm($id: ID!) {
    getSubmissionForm(id: $id) {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listSubmissionForms = /* GraphQL */ `
  query ListSubmissionForms(
    $filter: ModelSubmissionFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissionForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        firstName
        lastName
        email
        phone
        title
        companyName
        streetAddress
        city
        state
        zipcode
        country
        website
        fileName
        fileUrl
        message
        productId
        productName
        preferredStore
        industry
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getUserSession = /* GraphQL */ `
  query GetUserSession($id: ID!) {
    getUserSession(id: $id) {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const listUserSessions = /* GraphQL */ `
  query ListUserSessions(
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastDownloadFormSeen
        lastContactFormSeen
        lastTradeProgramFormSeen
        lastNewsletterFormSeen
        lastAddContactFormSeen
        lastDownloadNotificationSeen
        lastContactNotificationSeen
        lastTradeProgramNotificationSeen
        lastNewsletterNotificationSeen
        lastAddContactNotificationSeen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityId
        type
        creatorId
        createdAt
        action
        activityType
        payload {
          name
          note
          target
          targetId
          recipientContacts {
            id
            name
          }
          recipientCompanys {
            id
            name
          }
          attendeeContacts {
            id
            name
          }
          attendeeEmployees {
            id
            name
          }
          attendeeCompanys {
            id
            name
          }
          recipientOthers
          subject
          date
        }
        updatedAt
        creator {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getMessageContact = /* GraphQL */ `
  query GetMessageContact($id: ID!) {
    getMessageContact(id: $id) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listMessageContacts = /* GraphQL */ `
  query ListMessageContacts(
    $filter: ModelMessageContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeRecipient
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getMessageCompany = /* GraphQL */ `
  query GetMessageCompany($id: ID!) {
    getMessageCompany(id: $id) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listMessageCompanys = /* GraphQL */ `
  query ListMessageCompanys(
    $filter: ModelMessageCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeRecipient
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getMessageEmployee = /* GraphQL */ `
  query GetMessageEmployee($id: ID!) {
    getMessageEmployee(id: $id) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listMessageEmployees = /* GraphQL */ `
  query ListMessageEmployees(
    $filter: ModelMessageEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeRecipient
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        employee {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        rule
        permissionUserId
        viewActive
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        due_date
        description
        linkType
        creatorId
        employeeId
        status
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($id: ID!) {
    getAnalytics(id: $id) {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
export const listAnalyticss = /* GraphQL */ `
  query ListAnalyticss(
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        siteVisit
        topProduct
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContactUsFormsByCreatedAt = /* GraphQL */ `
  query ListContactUsFormsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactUsFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactUsFormsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        lastName
        email
        phone
        state
        zipcode
        message
        searchFirstName
        searchLastName
        createdAt
        contactID
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDownloadFileFormsByCreatedAt = /* GraphQL */ `
  query ListDownloadFileFormsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDownloadFileFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDownloadFileFormsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        lastName
        email
        productId
        productName
        searchFirstName
        searchLastName
        state
        zipcode
        createdAt
        contactID
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTradeProgramFormsByCreatedAt = /* GraphQL */ `
  query ListTradeProgramFormsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTradeProgramFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTradeProgramFormsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        searchFirstName
        lastName
        searchLastName
        email
        title
        companyName
        searchCompanyName
        streetAddress
        city
        state
        zipcode
        website
        phone
        fileName
        fileUrl
        createdAt
        contactID
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSubmissionFormsByCreatedAt = /* GraphQL */ `
  query ListSubmissionFormsByCreatedAt(
    $type: FormType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmissionFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissionFormsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        lastName
        email
        phone
        title
        companyName
        streetAddress
        city
        state
        zipcode
        country
        website
        fileName
        fileUrl
        message
        productId
        productName
        preferredStore
        industry
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listActivityByCreatedAt = /* GraphQL */ `
  query ListActivityByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityId
        type
        creatorId
        createdAt
        action
        activityType
        payload {
          name
          note
          target
          targetId
          recipientContacts {
            id
            name
          }
          recipientCompanys {
            id
            name
          }
          attendeeContacts {
            id
            name
          }
          attendeeEmployees {
            id
            name
          }
          attendeeCompanys {
            id
            name
          }
          recipientOthers
          subject
          date
        }
        updatedAt
        creator {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listActivityByCreator = /* GraphQL */ `
  query ListActivityByCreator(
    $creatorId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityByCreator(
      creatorId: $creatorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityId
        type
        creatorId
        createdAt
        action
        activityType
        payload {
          name
          note
          target
          targetId
          recipientContacts {
            id
            name
          }
          recipientCompanys {
            id
            name
          }
          attendeeContacts {
            id
            name
          }
          attendeeEmployees {
            id
            name
          }
          attendeeCompanys {
            id
            name
          }
          recipientOthers
          subject
          date
        }
        updatedAt
        creator {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPermissionsByUser = /* GraphQL */ `
  query ListPermissionsByUser(
    $permissionUserId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissionsByUser(
      permissionUserId: $permissionUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        rule
        permissionUserId
        viewActive
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listTasksByDueDate = /* GraphQL */ `
  query ListTasksByDueDate(
    $status: TaskStatus
    $due_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByDueDate(
      status: $status
      due_date: $due_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        due_date
        description
        linkType
        creatorId
        employeeId
        status
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listTasksByAssignedTo = /* GraphQL */ `
  query ListTasksByAssignedTo(
    $status: TaskStatus
    $employeeIdDue_date: ModelTaskByAssignedToCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByAssignedTo(
      status: $status
      employeeIdDue_date: $employeeIdDue_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        due_date
        description
        linkType
        creatorId
        employeeId
        status
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCollectionProduct = /* GraphQL */ `
  query GetCollectionProduct($id: ID!) {
    getCollectionProduct(id: $id) {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const listCollectionProducts = /* GraphQL */ `
  query ListCollectionProducts(
    $filter: ModelCollectionProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productName
        collectionId
        createdAt
        updatedAt
        collection {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getCollectionMaterial = /* GraphQL */ `
  query GetCollectionMaterial($id: ID!) {
    getCollectionMaterial(id: $id) {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const listCollectionMaterials = /* GraphQL */ `
  query ListCollectionMaterials(
    $filter: ModelCollectionMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionMaterials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        materialName
        collectionId
        createdAt
        updatedAt
        collection {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getHomepage = /* GraphQL */ `
  query GetHomepage($id: ID!) {
    getHomepage(id: $id) {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listHomepages = /* GraphQL */ `
  query ListHomepages(
    $filter: ModelHomepageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomepages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHomepageItem = /* GraphQL */ `
  query GetHomepageItem($id: ID!) {
    getHomepageItem(id: $id) {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listHomepageItems = /* GraphQL */ `
  query ListHomepageItems(
    $filter: ModelHomepageItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomepageItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getContactPage = /* GraphQL */ `
  query GetContactPage($id: ID!) {
    getContactPage(id: $id) {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const listContactPages = /* GraphQL */ `
  query ListContactPages(
    $filter: ModelContactPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        instagram
        sf_address
        sf_telephone
        sf_open_hours
        la_address
        la_telephone
        la_open_hours
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAboutus = /* GraphQL */ `
  query GetAboutus($id: ID!) {
    getAboutus(id: $id) {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listAboutuss = /* GraphQL */ `
  query ListAboutuss(
    $filter: ModelAboutusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAboutuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listContactsByFirstName = /* GraphQL */ `
  query ListContactsByFirstName(
    $type: String
    $first_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByFirstName(
      type: $type
      first_name: $first_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listContactsByLastName = /* GraphQL */ `
  query ListContactsByLastName(
    $type: String
    $last_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByLastName(
      type: $type
      last_name: $last_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listContactsByCreatedAt = /* GraphQL */ `
  query ListContactsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listContactsByUpdatedAt = /* GraphQL */ `
  query ListContactsByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listContactsByMailchimpId = /* GraphQL */ `
  query ListContactsByMailchimpId(
    $mailchimp_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByMailchimpId(
      mailchimp_id: $mailchimp_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: SearchableContactSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getPipeline = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listPipelines = /* GraphQL */ `
  query ListPipelines(
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPipelinesByContact = /* GraphQL */ `
  query ListPipelinesByContact(
    $type: String
    $contactId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByContact(
      type: $type
      contactId: $contactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPipelinesByEmployee = /* GraphQL */ `
  query ListPipelinesByEmployee(
    $type: String
    $employeeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByEmployee(
      type: $type
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPipelinesBySearchName = /* GraphQL */ `
  query ListPipelinesBySearchName(
    $type: String
    $searchName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesBySearchName(
      type: $type
      searchName: $searchName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPipelinesByCreatedAt = /* GraphQL */ `
  query ListPipelinesByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPipelinesByUpdatedAt = /* GraphQL */ `
  query ListPipelinesByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPipelinesByPhase = /* GraphQL */ `
  query ListPipelinesByPhase(
    $type: String
    $phase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByPhase(
      type: $type
      phase: $phase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        archived
        closedLostReason
        sendNotification
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCompanysByName = /* GraphQL */ `
  query ListCompanysByName(
    $type: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanysByName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCompanysByCreatedAt = /* GraphQL */ `
  query ListCompanysByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanysByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCompanysByUpdatedAt = /* GraphQL */ `
  query ListCompanysByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanysByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCompanysByMailchimpId = /* GraphQL */ `
  query ListCompanysByMailchimpId(
    $mailchimp_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanysByMailchimpId(
      mailchimp_id: $mailchimp_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchCompanys = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      name
      jobTitle
      email
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listTokensByUserId = /* GraphQL */ `
  query ListTokensByUserId(
    $tokenUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokensByUserId(
      tokenUserId: $tokenUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getGroupContact = /* GraphQL */ `
  query GetGroupContact($id: ID!) {
    getGroupContact(id: $id) {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listGroupContacts = /* GraphQL */ `
  query ListGroupContacts(
    $filter: ModelGroupContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mailchimp_status
        createdAt
        updatedAt
        contact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        group {
          id
          name
          mailchimp_list_id
          mailchimp_webhook_id
          mailchimp_tag_id
          mailchimp_tag_name
          mailchimp_newsletter_id
          type
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          companies {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getGroupCompany = /* GraphQL */ `
  query GetGroupCompany($id: ID!) {
    getGroupCompany(id: $id) {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listGroupCompanys = /* GraphQL */ `
  query ListGroupCompanys(
    $filter: ModelGroupCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mailchimp_status
        createdAt
        updatedAt
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        group {
          id
          name
          mailchimp_list_id
          mailchimp_webhook_id
          mailchimp_tag_id
          mailchimp_tag_name
          mailchimp_newsletter_id
          type
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          companies {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMailchimpConfig = /* GraphQL */ `
  query GetMailchimpConfig($id: ID!) {
    getMailchimpConfig(id: $id) {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const listMailchimpConfigs = /* GraphQL */ `
  query ListMailchimpConfigs(
    $filter: ModelMailchimpConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMailchimpConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dc
        accountname
        login_url
        api_endpoint
        access_token
        mailchimp_user_id
        mailchimp_user_email
        cognito_user_email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGmailConfig = /* GraphQL */ `
  query GetGmailConfig($id: ID!) {
    getGmailConfig(id: $id) {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const listGmailConfigs = /* GraphQL */ `
  query ListGmailConfigs(
    $filter: ModelGmailConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGmailConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognito_user_email
        gmail_address
        access_token
        refresh_token
        scope
        token_type
        expiry_date
        signature
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGmailConfigByCognitoEmail = /* GraphQL */ `
  query GetGmailConfigByCognitoEmail(
    $cognito_user_email: String
    $sortDirection: ModelSortDirection
    $filter: ModelGmailConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGmailConfigByCognitoEmail(
      cognito_user_email: $cognito_user_email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user_email
        gmail_address
        access_token
        refresh_token
        scope
        token_type
        expiry_date
        signature
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCalendarsByUser = /* GraphQL */ `
  query ListCalendarsByUser(
    $calendarUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarsByUser(
      calendarUserId: $calendarUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCalendarsByGoogleChannelId = /* GraphQL */ `
  query ListCalendarsByGoogleChannelId(
    $googleChannelId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarsByGoogleChannelId(
      googleChannelId: $googleChannelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEventsByGoogleEventId = /* GraphQL */ `
  query ListEventsByGoogleEventId(
    $googleEventId: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByGoogleEventId(
      googleEventId: $googleEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEventAttendee = /* GraphQL */ `
  query GetEventAttendee($id: ID!) {
    getEventAttendee(id: $id) {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listEventAttendees = /* GraphQL */ `
  query ListEventAttendees(
    $filter: ModelEventAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventAttendees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeAttendee
        eventAttendeeEventId
        createdAt
        updatedAt
        attendeeContact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        attendeeCompany {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        attendeeEmployee {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        event {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listEventAttendeesByEvent = /* GraphQL */ `
  query ListEventAttendeesByEvent(
    $eventAttendeeEventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventAttendeesByEvent(
      eventAttendeeEventId: $eventAttendeeEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeAttendee
        eventAttendeeEventId
        createdAt
        updatedAt
        attendeeContact {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        attendeeCompany {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        attendeeEmployee {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        event {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
